import React, {useState, useEffect} from "react";
import {Button, Typography} from "@mui/material";
import {requestToken} from "../services/Firebase";

const NotificationsButton = ({onTokenAcquired}) => {
    const [token, setToken] = useState(undefined);
    const [notificationPermission, setNotificationPermission] = useState(Notification.permission);

    const setUpToken = async () => {
        let newToken = await requestToken()
        if (newToken) {
            setToken(newToken)
            onTokenAcquired(newToken)
        }
    }
    // todo next steps: Send token to server, set server to use firebase for messaging not current thing
    const requestPermissions = async () => {
        let permission = await Notification.requestPermission();
        setNotificationPermission(permission)
        // If the user accepts, let's create a notification
        if (permission === "granted") {
            console.log("Got notification permisions " + permission)
            // await setUpToken()
        } else {
            console.log("Rip got permissions " + permission)
        }
    }


    useEffect(() => {
        if (notificationPermission === "granted") {
            setUpToken().catch(e => console.log("Failed to set up token " + e))
        }
    }, [notificationPermission])


    return (
        <div>
            {notificationPermission === "denied" ? (
                <Typography variant="body1" color="error">Notification permision denied sort it out</Typography>
            ) : (notificationPermission !== "granted" && (
                <Button
                    onClick={() => requestPermissions()}
                    variant="contained"
                    color="error"
                    size="small"
                >
                    Click to Setup Notification Permissions
                </Button>
            ))}
        </div>
    );
};

export default NotificationsButton;
